import React, { Component } from 'react';
import { SectionHeader } from './section-header'; 
import parse from "html-react-parser"; 

class SearchResult extends Component {
  render() {
    let articles = this.props.results || []; 

    return (
      <section id='search-results'>  
        <SectionHeader name='Search Results' />      

        { articles.map( item => (          
              <React.Fragment key={item.slug}>
                <label>News | { new Date(item.date).toISOString().slice(0,10) }</label>
              	<div className="article-item" style={{ marginTop: "10px", padding: "0px 3px 30px 3px", borderBottom: "solid 1px #ccc"}}>					      
					      <h2><a href={item.path}>{ parse(item.title)}</a></h2>					      
					        <p>{ parse(item.excerpt) }</p>
				        </div>
              </React.Fragment>
          )
        )}

        { !articles.length && (<div className="article-item"><h2>There were not any results for <i>"{this.props.q }"</i></h2></div>) }
      </section>
    ); 
  }
}

export default SearchResult;
