import React from "react";
import { graphql } from "gatsby";
import { Index } from "lunr";

// Common components & Layout 
import Layout from "../../layouts"; 
import { Container, Row, Col } from "react-bootstrap";

// Individual components that make up the page
// import Newsletter from "../../components/newsletter/newsletter";
import Hero from "../../components/common/Hero"; 
import SEO from "../../components/common/SEO";
import SearchResult from "../../components/common/SearchResult";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import SearchForm from "../components/search-form"

// We can access the results of the page GraphQL query via the data props
const SearchPage = ({ data, location }) => {
  
  // location.search.slice(1) gets rid of the "?" 
  const params = new URLSearchParams(location.search.slice(1));
  const q = params.get("q") || "";

  // LunrIndex is available via page query
  const { store } = data.LunrIndex;

  // Lunr in action here
  const index = Index.load(data.LunrIndex.index);
  let results = []

  try {
    results = index.search(q).map(
      ({ ref }) => {
      
      return {
        slug: ref,
        ...store[ref],
      }; 

    })
  } catch(error) {
    console.log(error)
  }

  return (
    <Layout>
      <SEO />
      <Hero title="Search Results" />        
      <Container style={{ position: "relative", minHeight: "72vh"}}>
            <Row className={"latest"}>
              <Col>
                <SearchResult q={q} results={results} />
              </Col>
            </Row>           
          </Container>      
    </Layout>      
  )
}

export default SearchPage; 
export const pageQuery = graphql`
  query {
    LunrIndex
  }
`